export const PARTNER_ID = "1";
export const CLIENT_ID = "gooseethahPeecied8Eegu4xeeseewuh";
export const ACQUIRING_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/acquiring";
export const APPROVER_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app";
export const CARD_HOLDER_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app";
export const CURRENCY_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/currency";
export const REGISTRATION_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/reg";
export const WALLET_API_BASE_URL = "https://vault-prod-api.stg.darknet.piefi.app/wallet";
export const STAND_URL = "https://vault.stg.darknet.piefi.app";
export const ZEN_DESK_ID = "b1a87a80-c4a1-4b63-9cb8-bedbe6d4be3c";
export const ZEN_DESK_NAME = "Vbanq";
export const EMAIL_HELP = "";
export const EMAIL_HELLO = "";
