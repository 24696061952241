var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { SubscriptionCard } from "./components/Card";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { EMAIL_HELLO } from "@/config";
import { PaymentPromoStore } from "@/pages/PaymentPage/stores/PaymentPromoStore";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useErrorToasts } from "@/shared/hooks/useErrorToasts";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { SubscriptionsInfoStore } from "@/shared/stores/SubscriptionsInfoStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
const optionsB = [
    "3 fiat accounts",
    "Unlimited crypto wallets",
    "Unlimited fiat and crypto balances",
    "Global payouts via SWIFT, SEPA, ACH, Fedwire, and other rails",
    "Receive funds in up to 21 fiat currencies",
    "Crypto exchange fees from 2%",
    "Fiat currency exchange fees 3%",
    "Free same currency transfers within our platform",
    "EUR and GBP accounts coming in early Q3",
    "VISA payment card launching in Q3",
    "24/7 customer support",
];
const optionsE = [
    "7 fiat accounts",
    "Unlimited crypto wallets",
    "Unlimited fiat and crypto balances",
    "Global payouts via SWIFT, SEPA, ACH, Fedwire, and other rails",
    "Receive funds in up to 21 fiat currencies",
    "Crypto exchange fees from 1.5%",
    "Fiat currency exchange fees 1.5%",
    "Free same currency transfers within our platform",
    "EUR and GBP accounts coming in early Q3",
    "VISA payment card launching in Q3",
    "Priority 24/7 customer support",
];
export const SubscriptionPage = observer(() => {
    var _a, _b, _c, _d, _e;
    const navigate = useNavigate();
    const companyInfo = useStore(CompanyInfoStore);
    const CompanyInfoProvider = useProvider(CompanyInfoStore);
    const [isOpen, setIsOpen] = useState(false);
    const verification = useGetVerificationInfo();
    const subscription = useStore(SubscriptionStore);
    const promo = useStore(PaymentPromoStore, subscription);
    const PromoProvider = useProvider(PaymentPromoStore);
    const SubscriptionsProvider = useProvider(SubscriptionStore);
    const subscriptionsInfo = useStore(SubscriptionsInfoStore);
    const SubscriptionsInfoProvider = useProvider(SubscriptionsInfoStore);
    useErrorToasts(companyInfo.errors);
    const selectPlan = (detailsId) => __awaiter(void 0, void 0, void 0, function* () {
        const success = yield subscription.select(detailsId);
        if (success) {
            navigate(generatePath(ROUTES.kyb));
        }
    });
    return (React.createElement(SubscriptionsInfoProvider, { value: subscriptionsInfo },
        React.createElement(SubscriptionsProvider, { value: subscription },
            React.createElement(PromoProvider, { value: promo },
                React.createElement(CompanyInfoProvider, { value: companyInfo },
                    ((_a = verification.data) === null || _a === void 0 ? void 0 : _a.status) === VerificationStatus.PENDING && React.createElement(UnderReviewPopup, null),
                    React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
                        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                            React.createElement(Typography, { textAlign: "center", type: "text32Bold" },
                                "Find the right plan for",
                                " ",
                                React.createElement(Typography, { type: "text32Bold", color: "deep-blue-500" }, "your financial goals")),
                            React.createElement(Typography, { color: "black-500", textAlign: "center" }, "Get unlimited access")),
                        React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(2,350px)", gap: "40px" },
                            React.createElement(SubscriptionCard, { title: "Business", fee: "795", monthly: "495", discount: (_b = promo.discountHighest) === null || _b === void 0 ? void 0 : _b.discount_percent, options: optionsB, onSelect: ((_c = subscriptionsInfo.details[0]) === null || _c === void 0 ? void 0 : _c.id)
                                    ? () => selectPlan(subscriptionsInfo.details[0].id)
                                    : undefined, disabled: !companyInfo.ready
                                    || companyInfo.loading
                                    || subscription.loading
                                    || !subscription.ready
                                    || subscriptionsInfo.loading
                                    || !verification.data }),
                            React.createElement(SubscriptionCard, { theme: "black", title: "Enterprise", fee: "1295", monthly: "995", options: optionsE, discount: (_d = promo.discountHighest) === null || _d === void 0 ? void 0 : _d.discount_percent, onSelect: ((_e = subscriptionsInfo.details[1]) === null || _e === void 0 ? void 0 : _e.id)
                                    ? () => selectPlan(subscriptionsInfo.details[1].id)
                                    : undefined, disabled: !companyInfo.ready
                                    || companyInfo.loading
                                    || subscription.loading
                                    || !subscription.ready
                                    || subscriptionsInfo.loading
                                    || !verification.data })),
                        React.createElement(Box, { padding: "30px", bgColor: "black-100", display: "flex", flexDirection: "column", gap: "30px", width: "100%", maxWidth: "710px", alignItems: "center", borderRadius: "22px", mb: "110px", boxSizing: "border-box" },
                            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                                React.createElement(Typography, { type: "text24Bold" }, "Need something a little different?"),
                                React.createElement(Typography, { type: "text14", color: "black-500" }, "Chat to our team about a plan that fits you needs")),
                            React.createElement(Button, { variant: "Black", width: "290px", onClick: () => {
                                    window.open(`mailto:${EMAIL_HELLO}`);
                                } }, "Request another plan")),
                        isOpen && (React.createElement(Popup, { width: "450px", bgColor: "general-green" },
                            React.createElement(PopupHead, { invert: true, onClose: () => setIsOpen(false) }),
                            React.createElement(PopupBody, null,
                                React.createElement(PopupContent, null,
                                    React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" },
                                        React.createElement(Typography, { color: "general-white", type: "text28Bold" }, "We'll be in touch soon"),
                                        React.createElement(Typography, { color: "general-white", textAlign: "center", type: "text14" }, "Our team will contact you with additional information regarding monthly plans"))),
                                React.createElement(Button, { variant: "White", onClick: () => setIsOpen(false) }, "Got it"))))))))));
});
